import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Mic, Clock, Settings, HelpCircle, User } from 'lucide-react';
import { useDoctor } from '../DoctorContext';

const Navigation: React.FC = () => {
  const location = useLocation();
  const { currentDoctor } = useDoctor();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <h1 className="text-xl font-bold text-indigo-600">AI Medical Scribe</h1>
            </div>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              <Link
                to="/"
                className={`${
                  location.pathname === '/'
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                <Home className="w-5 h-5 mr-1" />
                Home
              </Link>
              <Link
                to="/transcribe"
                className={`${
                  location.pathname === '/transcribe'
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                <Mic className="w-5 h-5 mr-1" />
                Transcribe
              </Link>
              <Link
                to="/past-summaries"
                className={`${
                  location.pathname === '/past-summaries'
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                <Clock className="w-5 h-5 mr-1" />
                Past Summaries
              </Link>
              <Link
                to="/settings"
                className={`${
                  location.pathname === '/settings'
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                <Settings className="w-5 h-5 mr-1" />
                Settings
              </Link>
              <Link
                to="/help"
                className={`${
                  location.pathname === '/help'
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                <HelpCircle className="w-5 h-5 mr-1" />
                Help
              </Link>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center">
              <User className="w-5 h-5 mr-2 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">
                {currentDoctor ? currentDoctor.name : 'Loading...'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;