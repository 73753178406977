import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

interface Doctor {
  id: number;
  name: string;
}

interface DoctorContextType {
  currentDoctor: Doctor | null;
  setCurrentDoctor: React.Dispatch<React.SetStateAction<Doctor | null>>;
  isLoading: boolean;
  error: string | null;
}

const DoctorContext = createContext<DoctorContextType | undefined>(undefined);

interface DoctorProviderProps {
  children: ReactNode;
}

export const DoctorProvider: React.FC<DoctorProviderProps> = ({ children }) => {
  const [currentDoctor, setCurrentDoctor] = useState<Doctor | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCurrentDoctor = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Assuming doctor id 1 is always logged in
        const response = await fetch('/api/doctors/1');
        if (!response.ok) {
          throw new Error('Failed to fetch doctor data');
        }
        const data = await response.json();
        setCurrentDoctor(data);
      } catch (err) {
        console.error('Error fetching current doctor:', err);
        setError('Failed to load doctor information. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentDoctor();
  }, []);

  return (
    <DoctorContext.Provider value={{ currentDoctor, setCurrentDoctor, isLoading, error }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctor = () => {
  const context = useContext(DoctorContext);
  if (context === undefined) {
    throw new Error('useDoctor must be used within a DoctorProvider');
  }
  return context;
};