import React from 'react';
import { Link } from 'react-router-dom';
import { Mic, FileText } from 'lucide-react';
import Navigation from './Navigation';

const Dashboard: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white overflow-hidden shadow-xl sm:rounded-lg">
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">Welcome to AI Medical Scribe</h2>
              <p className="text-gray-600 mb-6">
                Streamline your patient visit summaries with our advanced speech-to-text and AI-powered summarization technology.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-200">
                  <h3 className="text-lg font-semibold text-indigo-600 mb-2">Start Transcribing</h3>
                  <p className="text-gray-600 mb-4">Begin a new patient visit transcription using our real-time speech-to-text technology.</p>
                  <Link
                    to="/transcribe"
                    className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <Mic className="mr-2" size={18} />
                    Go to Transcriber
                  </Link>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-200">
                  <h3 className="text-lg font-semibold text-indigo-600 mb-2">View Summaries</h3>
                  <p className="text-gray-600 mb-4">Access and edit your AI-generated patient visit summaries.</p>
                  <Link
                    to="/summaries"
                    className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <FileText className="mr-2" size={18} />
                    View Summaries
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;