import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DoctorProvider } from './DoctorContext';
import Dashboard from './components/Dashboard';
import Transcriber from './components/Transcriber';
import Summary from './components/Summary';
import PastSummaries from './components/PastSummaries';
import Settings from './components/Settings';
import Help from './components/Help';

const App: React.FC = () => {
  return (
    <DoctorProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/transcribe" element={<Transcriber />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/past-summaries" element={<PastSummaries />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </Router>
    </DoctorProvider>
  );
};

export default App;