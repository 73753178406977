import React, { useState, useEffect, useCallback } from 'react';
import { X, Search, Calendar, User } from 'lucide-react';
import { useDoctor } from '../DoctorContext';
import Navigation from './Navigation';

interface Summary {
  id: number;
  content: string;
  created_at: string;
  patient_name: string;
}

const PastSummaries: React.FC = () => {
  const { currentDoctor } = useDoctor();
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [filteredSummaries, setFilteredSummaries] = useState<Summary[]>([]);
  const [patients, setPatients] = useState<string[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedSummary, setExpandedSummary] = useState<Summary | null>(null);

  const fetchSummaries = useCallback(async () => {
    if (!currentDoctor) return;

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`/api/summaries?doctorId=${currentDoctor.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch summaries');
      }
      const data: Summary[] = await response.json();
      setSummaries(data);
      
      const uniquePatients = Array.from(new Set(data.map(summary => summary.patient_name)));
      setPatients(uniquePatients);
    } catch (error) {
      console.error('Error fetching summaries:', error);
      setError('Failed to fetch summaries. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentDoctor]);

  useEffect(() => {
    fetchSummaries();
  }, [fetchSummaries]);

  const filterSummaries = useCallback(() => {
    let filtered = summaries;

    if (selectedPatient) {
      filtered = filtered.filter(summary => summary.patient_name === selectedPatient);
    }

    if (dateFilter) {
      filtered = filtered.filter(summary => {
        const summaryDate = new Date(summary.created_at).toISOString().split('T')[0];
        return summaryDate === dateFilter;
      });
    }

    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(summary => 
        summary.content.toLowerCase().includes(lowercasedTerm) ||
        summary.patient_name.toLowerCase().includes(lowercasedTerm)
      );
    }

    setFilteredSummaries(filtered);
  }, [summaries, selectedPatient, dateFilter, searchTerm]);

  useEffect(() => {
    filterSummaries();
  }, [summaries, selectedPatient, dateFilter, searchTerm, filterSummaries]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const toggleExpand = (summary: Summary) => {
    setExpandedSummary(expandedSummary?.id === summary.id ? null : summary);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Past Summaries</h2>
        
        <div className="mb-6 space-y-4 md:space-y-0 md:flex md:items-end md:space-x-4">
          <div className="flex-1">
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
              Search Summaries
            </label>
            <div className="relative">
              <input
                id="search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-3 py-2 text-gray-700 bg-white border rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Search by content or patient name"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex-1">
            <label htmlFor="patient-filter" className="block text-sm font-medium text-gray-700 mb-1">
              Filter by Patient
            </label>
            <div className="relative">
              <select
                id="patient-filter"
                value={selectedPatient}
                onChange={(e) => setSelectedPatient(e.target.value)}
                className="w-full pl-10 pr-3 py-2 text-gray-700 bg-white border rounded-lg focus:outline-none focus:border-blue-500 appearance-none"
              >
                <option value="">All Patients</option>
                {patients.map(patient => (
                  <option key={patient} value={patient}>{patient}</option>
                ))}
              </select>
              <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex-1">
            <label htmlFor="date-filter" className="block text-sm font-medium text-gray-700 mb-1">
              Filter by Date
            </label>
            <div className="relative">
              <input
                id="date-filter"
                type="date"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                className="w-full pl-10 pr-3 py-2 text-gray-700 bg-white border rounded-lg focus:outline-none focus:border-blue-500"
              />
              <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="mb-6 p-4 border rounded-lg bg-red-50 text-red-700">
            <p className="font-medium">{error}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredSummaries.map((summary) => (
              <div 
                key={summary.id} 
                className="bg-white overflow-hidden shadow-md rounded-lg hover:shadow-lg transition-all duration-300 cursor-pointer"
                onClick={() => toggleExpand(summary)}
              >
                <div className="px-6 py-5">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{summary.patient_name}</h3>
                  <p className="text-sm text-gray-600 mb-3">
                    Date: {formatDate(summary.created_at)}
                  </p>
                  <p className="text-gray-700 line-clamp-3">{summary.content}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {filteredSummaries.length === 0 && !isLoading && !error && (
          <p className="text-center text-gray-500 mt-6 text-lg">No summaries found matching the current filters.</p>
        )}
      </div>

      {expandedSummary && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] flex flex-col">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold">{expandedSummary.patient_name}</h3>
              <button 
                onClick={() => setExpandedSummary(null)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-4 overflow-y-auto flex-grow">
              <p className="text-sm text-gray-600 mb-4">
                Date: {formatDate(expandedSummary.created_at)}
              </p>
              <p className="text-gray-700 whitespace-pre-wrap">{expandedSummary.content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PastSummaries;
