import React from 'react';
import Navigation from './Navigation';

const Settings: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
          <p className="mt-4">Settings page is under construction.</p>
        </div>
      </div>
    </div>
  );
};

export default Settings;
