import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import { useDoctor } from '../DoctorContext';
import { GoogleGenerativeAI } from '@google/generative-ai';

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_GEMINI_API_KEY || '');

interface Patient {
  id: number;
  name: string;
}

const Summary: React.FC = () => {
  const [summary, setSummary] = useState('');
  const [transcript, setTranscript] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentDoctor } = useDoctor();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
  const [showNewPatientForm, setShowNewPatientForm] = useState(false);
  const [newPatientName, setNewPatientName] = useState('');

  useEffect(() => {
    fetchPatients();
    const receivedTranscript = location.state?.transcript || '';
    setTranscript(receivedTranscript);
    if (receivedTranscript) {
      generateSummary(receivedTranscript);
    }
  }, [location.state]);

  const fetchPatients = async () => {
    try {
      const response = await fetch('/api/patients');
      if (response.ok) {
        const data = await response.json();
        setPatients(data);
      } else {
        throw new Error('Failed to fetch patients');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
      setError('Failed to fetch patients. Please try again.');
    }
  };

  const handlePatientSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'new') {
      setShowNewPatientForm(true);
      setSelectedPatientId(null);
    } else {
      setSelectedPatientId(Number(value));
      setShowNewPatientForm(false);
    }
  };

  const handleNewPatientSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPatientName.trim()) {
      setError('Patient name cannot be empty');
      return;
    }
    try {
      const response = await fetch('/api/patients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newPatientName }),
      });
      if (response.ok) {
        const newPatient = await response.json();
        setPatients([...patients, newPatient]);
        setSelectedPatientId(newPatient.id);
        setShowNewPatientForm(false);
        setNewPatientName('');
      } else {
        throw new Error('Failed to create new patient');
      }
    } catch (error) {
      console.error('Error creating new patient:', error);
      setError('Failed to create new patient. Please try again.');
    }
  };

  const generateSummary = async (text: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const prompt = `You are an AI medical scribe assistant. Your task is to summarize the following medical transcript into a structured patient visit summary. Include relevant sections such as Patient Information, Chief Complaint, History of Present Illness, Past Medical History, Medications, Allergies, Physical Examination, Assessment, Plan, and Follow-up. If certain information is not available in the transcript, you may omit those sections. Here's the transcript:

      ${text}

      Please provide a concise and well-structured summary based on this transcript.`;

      const result = await model.generateContent(prompt);
      const response = await result.response;
      const generatedSummary = response.text();
      setSummary(generatedSummary);
    } catch (error) {
      console.error('Error generating summary:', error);
      setError('Failed to generate summary. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const saveTranscriptionAndSummary = async () => {
    if (!selectedPatientId) {
      setError('Please select a patient before saving.');
      return;
    }

    if (!currentDoctor) {
      setError('No doctor selected. Please ensure you are logged in.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Save transcription
      const transcriptionResponse = await fetch('/api/transcriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: transcript,
          doctorId: currentDoctor.id,
          patientId: selectedPatientId
        }),
      });

      if (!transcriptionResponse.ok) {
        const errorData = await transcriptionResponse.json();
        console.error('Transcription save error:', errorData);
        throw new Error(`Failed to save transcription: ${errorData.message || transcriptionResponse.statusText}`);
      }

      const transcriptionData = await transcriptionResponse.json();
      console.log('Transcription saved successfully:', transcriptionData);

      // Save summary
      const summaryResponse = await fetch('/api/summaries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transcriptionId: transcriptionData.id,
          content: summary,
          doctorId: currentDoctor.id,
          patientId: selectedPatientId
        }),
      });

      if (!summaryResponse.ok) {
        const errorData = await summaryResponse.json();
        console.error('Summary save error:', errorData);
        throw new Error(`Failed to save summary: ${errorData.message || summaryResponse.statusText}`);
      }

      const summaryData = await summaryResponse.json();
      console.log('Summary saved successfully:', summaryData);

      alert('Transcription and summary saved successfully!');
      navigate('/past-summaries');
    } catch (error) {
      console.error('Error saving data:', error);
      setError(`Failed to save data: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white overflow-hidden shadow-xl sm:rounded-lg">
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">AI-Generated Summary</h2>
              
              {isLoading ? (
                <div className="flex justify-center items-center h-48">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              ) : error ? (
                <div className="mb-4 p-4 border rounded-md bg-red-100 text-red-700">
                  <h3 className="text-xl font-semibold mb-2">Error</h3>
                  <p>{error}</p>
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <label htmlFor="patient-select" className="block text-sm font-medium text-gray-700 mb-2">
                      Select Patient
                    </label>
                    <select 
                      id="patient-select"
                      onChange={handlePatientSelect}
                      value={selectedPatientId || ''}
                      className="w-full p-2 border rounded-md"
                    >
                      <option value="">Select Patient</option>
                      {patients.map(patient => (
                        <option key={patient.id} value={patient.id}>{patient.name}</option>
                      ))}
                      <option value="new">New Patient</option>
                    </select>
                  </div>

                  {showNewPatientForm && (
                    <form onSubmit={handleNewPatientSubmit} className="mb-4">
                      <div className="flex items-center">
                        <input
                          type="text"
                          value={newPatientName}
                          onChange={(e) => setNewPatientName(e.target.value)}
                          placeholder="Enter patient name"
                          className="flex-grow p-2 border rounded-l-md"
                        />
                        <button
                          type="submit"
                          className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600"
                        >
                          Add Patient
                        </button>
                      </div>
                    </form>
                  )}

                  <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Original Transcript</h3>
                    <div className="w-full h-48 p-2 border rounded-md overflow-y-auto whitespace-pre-wrap">
                      {transcript}
                    </div>
                  </div>

                  {summary && (
                    <div className="mb-4">
                      <h3 className="text-xl font-semibold mb-2">Generated Summary</h3>
                      <div className="w-full h-64 p-2 border rounded-md overflow-auto whitespace-pre-wrap">
                        {summary}
                      </div>
                    </div>
                  )}

                  <button 
                    onClick={saveTranscriptionAndSummary}
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                    disabled={!selectedPatientId || !currentDoctor}
                  >
                    Save Transcription and Summary
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
