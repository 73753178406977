import React, { useState, useEffect, useRef } from 'react';
import { Mic, StopCircle, FileText, Edit2, Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import nlp from 'compromise';

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

const Transcriber: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [editableTranscript, setEditableTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const recognitionRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.maxAlternatives = 1;
      recognitionRef.current.lang = 'en-US';

      recognitionRef.current.onstart = () => {
        setIsRecording(true);
      };

      recognitionRef.current.onend = () => {
        setIsRecording(false);
      };

      recognitionRef.current.onresult = (event: any) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        if (finalTranscript) {
          setTranscript((prev) => {
            const newText = processNewText(finalTranscript);
            return formatTranscript(prev, newText);
          });
        }
        setInterimTranscript(interimTranscript);
      };

      recognitionRef.current.onerror = (event: any) => {
        console.error('Speech recognition error', event.error);
        setIsRecording(false);
        if (event.error === 'not-allowed') {
          alert('Microphone access is required for speech recognition. Please allow microphone access and try again.');
        }
      };
    } else {
      console.error('Speech recognition not supported');
      alert('Speech recognition is not supported in your browser. Please try using a modern browser like Chrome or Edge.');
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    setEditableTranscript(transcript);
  }, [transcript]);

  const processNewText = (newText: string): string => {
    let cleanedText = newText
      .replace(/\.+/g, '.')
      .replace(/\s+/g, ' ')
      .replace(/\s+\./g, '.')
      .trim();

    const doc = nlp(cleanedText);
    
    doc.sentences().forEach((sentence: any) => {
      let sentenceText = sentence.text().trim();
      sentenceText = sentenceText.charAt(0).toUpperCase() + sentenceText.slice(1);
      if (!sentenceText.match(/[.!?]$/)) {
        sentenceText += '.';
      }
      sentence.replaceWith(sentenceText);
    });

    return doc.text();
  };

  const formatTranscript = (prevTranscript: string, newText: string): string => {
    const maxLineLength = 100; // Maximum characters per line
    let formattedText = prevTranscript;

    // Add a line break if there's a natural pause (detected by punctuation)
    if (newText.match(/[.!?]$/)) {
      formattedText += (formattedText ? '\n' : '') + newText;
    } else {
      // If no natural pause, check if adding the new text exceeds the max line length
      const lastLineBreakIndex = formattedText.lastIndexOf('\n');
      const lastLine = formattedText.slice(lastLineBreakIndex + 1);
      
      if (lastLine.length + newText.length > maxLineLength) {
        formattedText += '\n' + newText;
      } else {
        formattedText += (formattedText ? ' ' : '') + newText;
      }
    }

    return formattedText;
  };

  const startRecording = () => {
    if (recognitionRef.current) {
      try {
        recognitionRef.current.start();
      } catch (error) {
        console.error('Error starting speech recognition:', error);
        setIsRecording(false);
        alert('Failed to start speech recognition. Please try again.');
      }
    } else {
      console.error('Speech recognition is not initialized');
      setIsRecording(false);
      alert('Speech recognition is not initialized. Please refresh the page and try again.');
    }
  };

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecording(false);
    // Process any remaining interim transcript
    if (interimTranscript) {
      setTranscript((prev) => {
        const newText = processNewText(interimTranscript);
        return formatTranscript(prev, newText);
      });
      setInterimTranscript('');
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = () => {
    setTranscript(editableTranscript);
    setIsEditing(false);
  };

  const handleTranscriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableTranscript(e.target.value);
  };

  const goToSummary = () => {
    navigate('/summary', { state: { transcript: editableTranscript } });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white overflow-hidden shadow-xl sm:rounded-lg">
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">AI Medical Scribe Transcriber</h2>
              
              <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                <button 
                  onClick={isRecording ? stopRecording : startRecording}
                  className={`flex-1 px-4 py-2 rounded-md text-white ${isRecording ? 'bg-red-500' : 'bg-blue-500'} flex items-center justify-center`}
                  disabled={isEditing}
                >
                  {isRecording ? <StopCircle className="inline-block mr-2" /> : <Mic className="inline-block mr-2" />}
                  {isRecording ? 'Stop Recording' : 'Start Recording'}
                </button>
                {!isEditing ? (
                  <button 
                    onClick={handleEditClick}
                    className="flex-1 px-4 py-2 rounded-md text-white bg-yellow-500 flex items-center justify-center"
                    disabled={!transcript}
                  >
                    <Edit2 className="inline-block mr-2" />
                    Edit Transcript
                  </button>
                ) : (
                  <button 
                    onClick={handleSaveEdit}
                    className="flex-1 px-4 py-2 rounded-md text-white bg-green-500 flex items-center justify-center"
                  >
                    <Save className="inline-block mr-2" />
                    Save Edits
                  </button>
                )}
                <button 
                  onClick={goToSummary}
                  className="flex-1 px-4 py-2 rounded-md text-white bg-purple-500 flex items-center justify-center"
                  disabled={!transcript || isEditing}
                >
                  <FileText className="inline-block mr-2" />
                  Go to Summary
                </button>
              </div>

              <div className="mb-4 p-4 border rounded-md">
                <h3 className="text-xl font-semibold mb-2">Transcript</h3>
                {isEditing ? (
                  <textarea
                    value={editableTranscript}
                    onChange={handleTranscriptChange}
                    className="w-full h-64 p-2 border rounded-md resize-none"
                  />
                ) : (
                  <div className="w-full h-64 p-2 border rounded-md overflow-auto">
                    <p className="whitespace-pre-wrap">{transcript}</p>
                    <p className="text-gray-500 italic">{interimTranscript}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transcriber;